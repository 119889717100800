import type { ReactNode } from 'react';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import type { DataProxy } from 'apollo-cache';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { ConfluenceWorkspaceAri, IdentityUserAri } from '@atlassian/ari';

import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { useRouteActions, useRouteDataRef, useTransitionId } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { getAGGClient } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';

import { GLOBAL_NAVIGATION_METRIC } from './perf.config';
import { useMenuItems, type MenuId } from './useMenuItems';
import { GlobalItemLoadables } from './GlobalItems/GlobalItemLoadables';
import { GlobalAppShortcuts } from './GlobalAppShortcuts';
import { VisuallyHiddenDivider } from './GlobalItems/VisuallyHiddenDivider';
import { MoreMenuItem } from './MoreMenuItem';
import { useFlyoutActions } from './FlyoutStore';
import type {
	GlobalNavigationComponentQuery as GlobalNavigationComponentQueryData,
	GlobalNavigationComponentQueryVariables,
	GlobalNavigationComponentQuery_settings_navigationCustomisation_sidebar_nodes as SettingsMenuItem,
} from './__types__/GlobalNavigationComponentQuery';
import { CustomizeSidebarDialog } from './CustomizeSidebar/CustomizeSidebarDialog';

export const PLATFORM_MENU_ID_PREFIX = 'confluence.sidebar.';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireMoreMenuClickedAnalytics,
);

const hasAccess = (accessStatus: AccessStatus | null) =>
	![AccessStatus.ANONYMOUS_ACCESS, AccessStatus.UNLICENSED_AUTHENTICATED_ACCESS, null].includes(
		accessStatus,
	);

export const GlobalNavigationComponent = ({
	withVisibleAppShortcuts,
}: GlobalNavigationComponentProps) => {
	const { accessStatus, cloudId, userId, activationId } = useSessionData();
	const [peekingId, setPeekingId] = useState<MenuId | undefined>(undefined);
	const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
	const routeDataRef = useRouteDataRef();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const { openFlyout, closeFlyout } = useFlyoutActions();
	const transitionId = useTransitionId();

	const [isCustomizeSidebarDialogVisible, setIsCustomizeSidebarDialogVisible] =
		useState<boolean>(false);

	useEffect(() => {
		if (transitionId && transitionId > 0) {
			//on any transition close open flyout. On initial Load transitionId = 0
			closeFlyout();
		}
	}, [closeFlyout, transitionId]);

	const moreMenuOnClickCallback = useCallback(
		(menuId: MenuId) => {
			setIsMoreMenuOpen(!isMoreMenuOpen);

			// TODO: it would be great if we could refactor the following menu-specific logic into the respective menu components
			if (menuId === 'customize') {
				setIsCustomizeSidebarDialogVisible(true);
				void fireClickAnalytics('customiseSidebar');
			} else {
				void fireClickAnalytics(menuId);
			}

			if (menuId === 'recent' || menuId === 'starred' || menuId === 'spaces') {
				openFlyout(menuId);
				setPeekingId(menuId);
			}

			if (menuId === 'apps') {
				push('/wiki/marketplace/discover');
			}
		},
		[fireClickAnalytics, isMoreMenuOpen, openFlyout, push],
	);

	const { data, error } = useQuery<
		GlobalNavigationComponentQueryData,
		GlobalNavigationComponentQueryVariables
	>(GlobalNavigationComponentQuery, {
		client: getAGGClient(),
		variables: {
			entityAri: ConfluenceWorkspaceAri.create({ siteId: cloudId, activationId }).toString(),
			ownerAri: userId ? IdentityUserAri.create({ userId }).toString() : '',
		},
		skip: !userId || !fg('confluence_nav_4_beta'),
	});

	const platformCustomizations = useMemo(() => {
		if (data?.settings_navigationCustomisation?.sidebar?.nodes) {
			return data.settings_navigationCustomisation.sidebar.nodes.map((node) => ({
				menuId: node.menuId?.replace(PLATFORM_MENU_ID_PREFIX, '') as MenuId,
				visible: Boolean(node.visible),
			}));
		}
		return [];
	}, [data]);

	const isPermitted = hasAccess(accessStatus);

	const { globalMenuItems, moreMenuItems } = useMenuItems(
		isPermitted,
		platformCustomizations,
		withVisibleAppShortcuts,
	);

	// The visible app shortcuts:
	// "Company Hub" is the first and only app shortcut at the time of this writing so the naming and abstraction here are as simple as possible.
	let companyHubAppShortcut: ReactNode = null;
	// The item "Customize sidebar" when it's one of the visible global items and app shortcuts:
	let globalCustomizeMenuItem: ReactNode = null;

	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'sideNavigation-globalNavigation',
			}}
		>
			{globalMenuItems.map(({ menuId }) => {
				const Component = GlobalItemLoadables[menuId];
				let node: ReactNode = null;
				if (Component) {
					node = (
						<Component
							key={menuId}
							setIsCustomizeSidebarDialogVisible={setIsCustomizeSidebarDialogVisible}
						/>
					);
					// All global items stay together and all app shortcuts stay together, they are separate groups.
					// I.e. the app shortcuts should be after a peeking global item (if any).
					// This is the render of the global items section so skip the app shortcuts (of which there is only Comopany Hub at Team '25 US) here and render them later.
					if (fg('company-hub-pseudo-app')) {
						if (menuId === 'companyHub') {
							companyHubAppShortcut = node;
							return null;
						} else if (menuId === 'customize') {
							// The item "Customize sidebar" should be:
							// - after the app shortcuts if any;
							// - visually divided from the preceeding global items or app shortcuts.
							globalCustomizeMenuItem = node;
							return null;
						}
					}
				}
				return node;
			})}
			{moreMenuItems.map((result) => {
				const Component = GlobalItemLoadables[result.menuId];
				return (
					Component && (
						<Component
							key={result.menuId}
							isHidden
							peekingId={peekingId}
							setPeekingId={setPeekingId}
							setIsCustomizeSidebarDialogVisible={setIsCustomizeSidebarDialogVisible}
						/>
					)
				);
			})}

			{/* Global App Shortcuts */}
			{withVisibleAppShortcuts && fg('nav4_global_links_confluence') && <GlobalAppShortcuts />}

			{/* The app shortcuts section is after and visually divided from the global items section: */}
			{companyHubAppShortcut && (
				<>
					<VisuallyHiddenDivider />
					{companyHubAppShortcut}
				</>
			)}
			{/* The item "Customize sidebar" is after and visually divided from the global items and/or app shortcuts sections: */}
			{globalCustomizeMenuItem && (
				<>
					<VisuallyHiddenDivider />
					{globalCustomizeMenuItem}
				</>
			)}
			{isPermitted && (
				<>
					<MoreMenuItem
						moreMenuItems={moreMenuItems}
						isOpen={isMoreMenuOpen}
						onClick={moreMenuOnClickCallback}
						toggleMenu={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
						peekingId={peekingId}
					/>
					<VisuallyHiddenDivider />
				</>
			)}
			{error && <ErrorDisplay error={error} />}
			{isCustomizeSidebarDialogVisible && fg('confluence_nav_4_beta') && (
				<CustomizeSidebarDialog
					globalMenuItems={globalMenuItems}
					moreMenuItems={moreMenuItems}
					onClose={() => setIsCustomizeSidebarDialogVisible(false)}
				/>
			)}
			<PageSegmentLoadEnd metric={GLOBAL_NAVIGATION_METRIC} />
		</ErrorBoundary>
	);
};

type GlobalNavigationComponentProps = {
	withVisibleAppShortcuts?: boolean;
};

export const updateNavigationCustomization = (
	cache: DataProxy,
	variables: GlobalNavigationComponentQueryVariables,
	nodes: SettingsMenuItem[],
) => {
	cache.writeQuery({
		query: GlobalNavigationComponentQuery,
		variables,
		data: {
			settings_navigationCustomisation: {
				__typename: 'NavigationCustomisation',
				sidebar: {
					__typename: 'SidebarCustomisation',
					nodes,
				},
			},
		},
	});
};

const GlobalNavigationComponentQuery = gql`
	query GlobalNavigationComponentQuery($entityAri: ID!, $ownerAri: ID!) {
		settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
			sidebar {
				nodes {
					menuId
					visible
				}
			}
		}
	}
`;
