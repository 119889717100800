import React from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

/**
 * The visible global items and app shortcuts use dividers that are visualized as spacing only, not lines.
 * (In contrast to the hidden global items and app shortcuts i.e. the ones in the "More" menu which use dividers that are visualized as lines.)
 */
export const VisuallyHiddenDivider = () => <hr role="presentation" css={dividerStyles} />;

const dividerStyles = css({
	marginBlock: token('space.075'),
	border: '0',
	visibility: 'hidden',
});
